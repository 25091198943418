import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { IPage } from "core/store/pages/slice";
import ModalLoader from "core/components/ModalLoader/ModalLoader";
import { replaceUnicode } from "core/features/strings";
import { StyledPageOrPost, colors } from "THE_BLOG/THE_BLOG_GlobalStyle";
import { useWidthWindowSize } from "core/features/device";
import { useEffect } from "react";
import { SET_IS_MENU_OPEN } from "THE_BLOG/store/menu/actions";
import $ from 'jquery'
import { isPostErrorSelector, isPostLoadingSelector, isPostSuccessSelector, postsSelector } from "core/store/posts/selectors";
import LoadingSpinner from "./LoadingSpinner/LoadingSpinner";
import Hero from "./Hero";
import { scrollUp } from "core/features/utils";
import { v4 as uuidv4 } from 'uuid';
import Logo from "./Logo";

const Post = () => {
    const isMobile = useWidthWindowSize() >= 768 ? false : true
    const posts = useSelector(postsSelector)
    const isPostLoading = useSelector(isPostLoadingSelector)
    const isPostError = useSelector(isPostErrorSelector)
    const isPostSuccess = useSelector(isPostSuccessSelector)
    const { postIDSlug } = useParams()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(SET_IS_MENU_OPEN(false))
        $(`body`).css("overflow", "unset")
    }, [])
    useEffect(() => {
        scrollUp()
    }, []);

    return (
            <ModalLoader 
                loading={isPostLoading}
                error={isPostError}
                success={isPostSuccess}
                componentOnLoading={<LoadingSpinner />}
                componentOnError={<LoadingSpinner />}
                componentOnSuccess={
                    <StyledPageOrPost ismobile={isMobile}>
                    {!isMobile && <Logo /> }
                        {
                            posts.map((post: IPage) => {
                                return(
                                    post.id === Number(postIDSlug) ?
                                        <div key={uuidv4()} className="pageOrPost_container">
                                            <Hero isMobile={isMobile} img={post.featured_image_large} borders={true}/>
                                            <div className="pageOrPost_container__article">
                                                <h1>{replaceUnicode(post.title.rendered)}</h1>
                                                <div dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
                                            </div>
                                        </div>
                                    :
                                        <></>
                                )
                            })
                            
                        }
                    </StyledPageOrPost>
                }
            />
    );
  }

  export default Post