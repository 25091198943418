import { useWidthWindowSize } from "core/features/device"
import Events from "./Events"
import Hero from "./Hero"
import Bio from "./Bio"
import { useDispatch, useSelector } from "react-redux"
import { isPostErrorSelector, isPostLoadingSelector, isPostSuccessSelector, postsSelector } from "core/store/posts/selectors"
import ModalLoader from "core/components/ModalLoader/ModalLoader"
import LoadingSpinner from "./LoadingSpinner/LoadingSpinner"
import { useEffect } from "react"
import { SET_IS_MENU_OPEN } from "THE_BLOG/store/menu/actions"
import bgImage from '../imgs/davide/gilet_supercut.jpg'
import $ from 'jquery'
import { menuRoutesSelector } from "core/store/menu/selectors"
import { scrollUp } from "core/features/utils"
import Logo from "./Logo"
import { StyledPageOrPost } from "THE_BLOG/THE_BLOG_GlobalStyle"
import Youtube from "./Youtube"

const Home = () => {
    const isMobile = useWidthWindowSize() >= 768 ? false : true
    const dispatch = useDispatch()

    const menuRoutes = useSelector(menuRoutesSelector)

    const posts = useSelector(postsSelector)
    const isPostLoading = useSelector(isPostLoadingSelector)
    const isPostError = useSelector(isPostErrorSelector)
    const isPostSuccess = useSelector(isPostSuccessSelector)
    
    useEffect(() => {
        dispatch(SET_IS_MENU_OPEN(false))
        $(`body`).css("overflow", "unset")
    }, [])
    useEffect(() => {
      scrollUp()
    }, []);

    return(
        <ModalLoader 
            loading={isPostLoading}
            error={isPostError}
            success={isPostSuccess}
            componentOnLoading={<LoadingSpinner />}
            componentOnError={<LoadingSpinner />}
            componentOnSuccess={
                <>
                  {
                    !isMobile ? 
                      <StyledPageOrPost ismobile={isMobile}>
                        <Logo />
                        <div>
                          <Hero isMobile={isMobile} img={bgImage} paddingTop="14vw" borders={false}/>
                        </div>
                      </StyledPageOrPost>
                    :
                        <div>
                          <Hero isMobile={isMobile} img={bgImage} paddingTop="14vw" borders={false}/>
                        </div>
                  }
                  <Bio />
                  {/* <Youtube videoId="rHR112SNGXo?si=rt93oFsbkyFL6WP9" /> */}
                  {/* <Shows isMobile={isMobile}/> */}
                  <Events isMobile={isMobile} posts={posts} menuRoutes={menuRoutes}/>
                  <Youtube videoId="RCwoOdnyr3k?si=5m1_bHI0ExAs4i44" />
                </>
            }
        />
        // <LoadingSpinner />
    )
}

export default Home