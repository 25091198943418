type IModalLoader = {
    loading: boolean,
    error: boolean,
    success: boolean,
    componentOnLoading?: any,
    componentOnError?: any,
    componentOnSuccess: any,
}

const ModalLoader = ({loading, error, success, componentOnLoading, componentOnError, componentOnSuccess, } : IModalLoader) => {
    return(
        <>
            {
                loading 
                ? 
                componentOnLoading && componentOnLoading
                : 
                error  
                ? 
                componentOnError && componentOnError 
                : 
                success  
                ?
                componentOnSuccess
                :
                <></>
            }
        </>
    )
} 

export default ModalLoader
