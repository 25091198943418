//SOURCE
export const the_blog_protocol = 'https' /** ! no httpS if localhost */
export const the_blog_domain = 'davidefabbrocino.it' /** e.g.: mydomain.com or localhost*/
export const the_blog_WPFolder = 'wordpress'
//MENU
export const the_blog_menu = 'menu' /** must be the same of wordpress menu's name */
export const the_blog_homeButtonName = `Davide Fabbrocino` /** home button menu */
//HEAD
export const the_blog_title = 'Davide Fabbrocino'
export const the_blog_description = 'Davide Fabbrocino ...'
//CATEGORIES
export const category_monologhi = 4
//MAILCHIMP
//export const mailchimp_URL = 'https://Davide Fabbrocino.us20.list-manage.com/subscribe/post?u=83659823131088b63f5ee6d0d&amp;id=825fac866e&amp;f_id=00574de6f0'
//LANGUAGE
export const ita = 'ita'
export const eng = 'eng'
export const defaultLanguage = ita
//PREVIEW
export const preview = 'preview'
//OTHERS