import { homeButtonName, pathHome } from "core/settings/settings"
import { Link } from "react-router-dom"

const SimpleFooter = () => {
    return(
        <>
            <div className="_simpleFooter">
                <Link to={pathHome}>contact {homeButtonName}</Link>
            </div>
        </>
    )
}

export default SimpleFooter