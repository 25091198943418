import { useParams } from "react-router";
import { isPageErrorSelector, isPageLoadingSelector, isPageSuccessSelector, pagesSelector } from "../../core/store/pages/selectors";
import { useDispatch, useSelector } from "react-redux";
import { IPage } from "core/store/pages/slice";
import ModalLoader from "core/components/ModalLoader/ModalLoader";
import { replaceUnicode } from "core/features/strings";
import { StyledPageOrPost, colors } from "THE_BLOG/THE_BLOG_GlobalStyle";
import { useWidthWindowSize } from "core/features/device";
import { useEffect } from "react";
import { SET_IS_MENU_OPEN } from "THE_BLOG/store/menu/actions";
import $ from 'jquery'
import LoadingSpinner from "./LoadingSpinner/LoadingSpinner";
import Hero from "./Hero";
import { scrollUp } from "core/features/utils";
import { v4 as uuidv4 } from 'uuid';
import Logo from "./Logo";

const Page = () => {
    const isMobile = useWidthWindowSize() >= 768 ? false : true
    const pages = useSelector(pagesSelector)
    const isPageLoading = useSelector(isPageLoadingSelector)
    const isPageError = useSelector(isPageErrorSelector)
    const isPageSuccess = useSelector(isPageSuccessSelector)
    const { pageIDSlug } = useParams()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(SET_IS_MENU_OPEN(false))
        $(`body`).css("overflow", "unset")
    }, [])
    useEffect(() => {
        scrollUp()
      }, []);

    return (
            <ModalLoader 
                loading={isPageLoading}
                error={isPageError}
                success={isPageSuccess}
                componentOnLoading={<LoadingSpinner />}
                componentOnError={<LoadingSpinner />}
                componentOnSuccess={
                    <StyledPageOrPost ismobile={isMobile}>
                    {!isMobile && <Logo /> }
                        {
                            pages.map((page: IPage) => {
                                return(
                                    page.id === Number(pageIDSlug) ?
                                        <div key={uuidv4()} className="pageOrPost_container">
                                            <Hero isMobile={isMobile} img={page.featured_image_large} borders={true}/>
                                            <div className="pageOrPost_container__article">
                                                <h1>{replaceUnicode(page.title.rendered)}</h1>
                                                <div dangerouslySetInnerHTML={{ __html: page.content.rendered }} />
                                            </div>
                                        </div>
                                    :
                                        <></>
                                )
                            })
                            
                        }
                    </StyledPageOrPost>
                }
            />
    );
  }

  export default Page