import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';

// Wrapper per le icone social
const SocialIconsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

const SocialIcon = styled.a`
  color: #000; 
  font-size: 24px; 
  transition: color 0.3s; 

  &:hover {
    color: #555; 
  }
`;

const SocialIcons = () => (
  <SocialIconsWrapper>
    <SocialIcon href="https://www.instagram.com/davidefabbrocino_attore/" target="_blank">
      <FontAwesomeIcon icon={faInstagram} />
    </SocialIcon>
    <SocialIcon href="https://www.youtube.com/@davidefabbrocino2549" target="_blank">
      <FontAwesomeIcon icon={faYoutube} />
    </SocialIcon>
    {/* <SocialIcon href="https://www.tiktok.com/@" target="_blank">
      <FontAwesomeIcon icon={faTiktok} />
    </SocialIcon>
    <SocialIcon href="https://www.facebook.com/@" target="_blank">
      <FontAwesomeIcon icon={faFacebookF} />
    </SocialIcon> */}
  </SocialIconsWrapper>
);

export default SocialIcons;
