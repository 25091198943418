import { useWidthWindowSize } from "core/features/device";
import styled from "styled-components"

type IYT = {
  videoId: string
}

const VideoWrapper = styled.div<{
  ismobile: boolean
}>`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  padding-top: 25px;
  margin-bottom: ${props => props.ismobile ? '9vw' : '6vw'};
  height: 0;
`;

const VideoIframe = styled.iframe`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 500px;
  border: 0;
`;

const Youtube = ({videoId}: IYT) => {
  const isMobile = useWidthWindowSize() >= 768 ? false : true
  const src = `https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1`;
  return (
    // <VideoWrapper ismobile={isMobile} className="Youtube">
      <VideoIframe
        className="Youtube"
        src={src}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></VideoIframe>
    // </VideoWrapper>
  );
};

export default Youtube