import { colors } from 'THE_BLOG/THE_BLOG_GlobalStyle';
import { removeAllSpaces, upperCaseFirstChar } from 'core/features/strings';
import { menuRoutesSelector } from 'core/store/menu/selectors';
import { IMenuForMenu } from 'core/store/menu/slice';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid';
import { isMenuOpenSelector } from 'THE_BLOG/store/menu/selectors';
import { pathHome } from 'core/settings/settings';
import { URLname, scrollUp } from 'core/features/utils';
import { the_blog_title } from 'THE_BLOG/THE_BLOG_Settings';

const StyledMenu = styled.nav<{
  ismobile: boolean
  ismenuopen: boolean
}>`
  font-family: 'Lato', sans-serif;
  display: ${props => props.ismenuopen ? 'flex' : 'none'};
  padding-bottom: ${props => props.ismobile ? '5vh' : 'unset'};
  font-size: 5vw;
  background: ${colors.due};
  height: ${props => props.ismobile ? '100vh' : 'unset'};
  width: ${props => props.ismobile ? '100vw' : 'unset'};
  justify-content: ${props => props.ismobile ? 'center' : 'center'};

  ul {
    display: flex;
    gap: 0;
    background: ${colors.due};
    flex-direction: column;
    align-items: center;
    width: 100%;

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      list-style: none;
      width: 100%;
      text-align: center;
      border-top: 1px solid ${colors.quattro};
      height: ${props => props.ismobile ? 'unset' : '7vw'};


      a{
        font-size: ${props => props.ismobile ? '15vw' : '5vw'};
        color: ${colors.uno};
        height: 100%;
        width: 100%;
        text-transform: uppercase;
      }
    } 

    li:last-of-type {
      border-bottom: 1px solid ${colors.quattro};
    }
  }
`
const StyledMenuList = styled.ul<{
  ismobile: boolean
}>`
  display: flex;
  flex-direction: ${props => props.ismobile ? 'column' : 'unset'};
  align-items: ${props => props.ismobile ? 'center' : 'unset'};
  gap: 3vw;
  padding-top: ${props => props.ismobile ? '24vw' : 'unset'};

  li {
  list-style: none;

    a{
      color: ${colors.uno};
    }
  } 
`

const Menu = ({isMobile}: {isMobile: boolean}) => {
  const menuRoutes = useSelector(menuRoutesSelector)
  const isMenuOpen = useSelector(isMenuOpenSelector)
  return (
    <StyledMenu className="Menu" ismobile={isMobile} ismenuopen={isMenuOpen}>
      <StyledMenuList ismobile={isMobile}>
        {
          <>
            {
              !URLname().includes(removeAllSpaces(the_blog_title.toLowerCase())) &&
                <li key={uuidv4()}>
                  <Link to={pathHome} className='home'>
                    {upperCaseFirstChar("homepage")}
                  </Link>
                </li>
            }
            {
              menuRoutes.map((route: IMenuForMenu) => {
                  const linkRoute = `${route.object}/${route.title}/${route.object_id}`
                  return(
                    !URLname().includes(removeAllSpaces(route.title.toLowerCase())) ?
                      <li key={uuidv4()} onClick={() => scrollUp()}>
                          <Link to={linkRoute.toLowerCase()}>
                              {upperCaseFirstChar(route.title)}
                          </Link>
                      </li>
                    :
                      <></>
                  )
              })
            }
          </>
        }
      </StyledMenuList>
    </StyledMenu>   
  );
};

export default Menu;
