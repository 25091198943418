import { createSlice } from "@reduxjs/toolkit";

export type IMenuForMenu = any

export type IMenu = {
  // isMenuButtonVisible: boolean
  isMenuOpen: boolean
  isRouteLoading: boolean
  isRouteError: boolean
  isRouteSuccess: boolean
  items: IMenuForMenu[]
}
const initialState: IMenu = {
  // isMenuButtonVisible: false,
  isMenuOpen: false,
  isRouteLoading: false,
  isRouteError: false,
  isRouteSuccess: false,
  items: [],
};

const menu = createSlice({
  name: 'menu',
  initialState: initialState,
  reducers: {
      // SET_IS_MENUBUTTON_VISIBLE: (state, action) => {
      //     state.isMenuButtonVisible = action.payload;
      // },
      TOGGLE_MENU: (state) => {
        state.isMenuOpen = !state.isMenuOpen
      },
      SET_IS_MENU_OPEN: (state, action) => {
          state.isMenuOpen = action.payload;
      },
      FETCH_MENUROUTES_BEGIN: (state) => {
        state.isRouteLoading = true;
        state.isRouteError = false;
        state.isRouteSuccess = false;
      },
      FETCH_MENUROUTES_FAILURE: (state, action) => {
        state.isRouteLoading = false;
        state.isRouteError = action.payload;
        state.isRouteSuccess = false;
      },
      FETCH_MENUROUTES_SUCCESS: (state, action) => {
        state.isRouteLoading = false;
        state.isRouteError = false;
        state.isRouteSuccess = true;
        state.items = action.payload
      },
  }
})

export default menu