import { Route, Routes, Navigate, useSearchParams } from "react-router-dom";
import { pathHome, pathCategory, pathPage, pathPost, pathPostPreview} from "./settings/settings";
import Header from "./site/Header/Header";
import Home from "./site/Home/Home";
import Category from "./site/Category/Category";
import Page from "./site/Page/Page";
import Post from "./site/Post/Post";
import { ICustomizeSite } from "./model/model";
import Footer from "./site/Footer/Footer";
import { useDispatch } from "react-redux";
import { FETCH_POSTS_BEGIN, FETCH_POSTS_FAILURE, FETCH_POSTS_SUCCESS } from "./store/posts/actions";
import { API_menuRoutes, API_pagesArchive, API_postsArchive } from "./settings/API/WPRestAPI";
import { FETCH_MENUROUTES_BEGIN, FETCH_MENUROUTES_FAILURE, FETCH_MENUROUTES_SUCCESS } from "./store/menu/actions";
import { GlobalStyle } from "THE_BLOG/THE_BLOG_GlobalStyle";
import { FETCH_PAGES_BEGIN, FETCH_PAGES_FAILURE, FETCH_PAGES_SUCCESS } from "./store/pages/actions";
import { useEffect } from "react";
import { preview } from "THE_BLOG/THE_BLOG_Settings";

const PreviewComponent = () => {
    const [searchParams] = useSearchParams();
    const postType = searchParams.get("postType")
    const postId = searchParams.get("postId")
    const baseUrl = window.location.href.substring(0, window.location.href.indexOf('#')+1);
    const newUrl = `${baseUrl}/${postType}/${preview}/${postId}`    

    useEffect(() => {
        window.location.href = newUrl
    }, [])
    
    return null
}

const Root = ({custom}: {custom: ICustomizeSite}) => {                                                      
    const dispatch = useDispatch()

    dispatch(FETCH_MENUROUTES_BEGIN())
    fetch(API_menuRoutes)
        .then(resp => resp.json())
        .then(json => dispatch(FETCH_MENUROUTES_SUCCESS(json.items)))
        .catch(error => dispatch(FETCH_MENUROUTES_FAILURE(true)))
        
    dispatch(FETCH_POSTS_BEGIN())
    fetch(API_postsArchive)
        .then(resp => resp.json())
        .then(json => dispatch(FETCH_POSTS_SUCCESS(json)))
        .catch(error => dispatch(FETCH_POSTS_FAILURE(true)))
    
    dispatch(FETCH_PAGES_BEGIN())
    fetch(API_pagesArchive)
        .then(resp => resp.json())
        .then(json => dispatch(FETCH_PAGES_SUCCESS(json)))
        .catch(error => dispatch(FETCH_PAGES_FAILURE(true)))

    return(
            <>
                <GlobalStyle />
                <main>
                    <Header custom={custom.header} />
                    <Routes>
                        <Route path="*" element={<Navigate to={pathHome} />} />
                        <Route path={pathHome} element={<Home custom={custom.home} />} />
                        <Route path={pathPage} element={<Page custom={custom.page} /> } />
                        <Route path={pathPost} element={<Post custom={custom.post} /> } />
                        <Route path={pathCategory} element={<Category custom={custom.category} /> } />
                        <Route path={pathPostPreview} element={<PreviewComponent /> } />
                        {/* <Route path={pathCustom} element={<Custom custom={custom.custom} /> } /> */}
                        {/* <Route path={pathHelp} element={<Help custom={custom.help} /> } /> */}
                    </Routes>
                </main>
                <Footer custom={custom.footer} /> 
            </>
    )
}

export default Root

