import styled from "styled-components";
import { colors } from "THE_BLOG/THE_BLOG_GlobalStyle";
import { v4 as uuidv4 } from 'uuid';
import { cleanWPtextWithDangerousHTML } from "core/features/strings";
import { Link } from "react-router-dom";
import Youtube from "./Youtube";
import ServiceButton from "./ServiceButton";

type IEvents = {
    isMobile: boolean,
    posts: any[],
    menuRoutes: any[],
}

const StyledEvents = styled.div<{
    ismobile: boolean
}>`
    font-family: 'Lato', sans-serif;
    background-color: ${colors.tre};
    color: ${colors.uno};
    margin: ${props => props.ismobile ? '4vw' : '2vw 15vw 2vw 15vw'};
    padding-top: ${props => props.ismobile ? 'unset' : 'unset'};
    
    h1,p,br {
        padding-bottom: ${props => props.ismobile ? '7vw' : '5vw'};
    }
    
    h1 {
        color: ${colors.uno};
        font-size: ${props => props.ismobile ? '4.8vw' : '3vw'};
        text-transform: uppercase;
        font-weight: 900;
        padding-bottom: ${props => props.ismobile ? '4vw' : '3vw'};
    }
    
    h3 {
        color: ${colors.quattro};
        font-size: ${props => props.ismobile ? '4.8vw' : '3vw'};
        padding-bottom: ${props => props.ismobile ? '4vw' : '3vw'};
        text-transform: uppercase;
    }

    ul {
        background-color: ${colors.tre};
    }
`;
const StyledEventRow = styled.div<{
    ismobile: boolean
}>`
    display: flex;
    flex-direction: ${props => props.ismobile ? 'column' : 'column'};
    align-items: center;
    font-size: ${props => props.ismobile ? '5vw' : '2vw'};
    padding: ${props => props.ismobile ? '3vw' : '2vw'};
    margin-bottom: ${props => props.ismobile ? '9vw' : '6vw'};
    border-radius: 22px;
    background-color: ${props => props.ismobile ? 'unset' : colors.tre};
    gap: ${props => props.ismobile ? '3vw' : '1vw'};
    border: ${props => props.ismobile ? '2px solid' : '4px solid'};
    border-color: ${props => props.ismobile ? colors.quattro : colors.quattro};

    div {
        flex: 1;
        text-align: center;
        text-transform: uppercase;
    }

    .dates {
        display: flex;
        align-items: center;
        widtH: 100%;
        flex-direction: ${props => props.ismobile ? 'column' : 'row'};
        gap: ${props => props.ismobile ? '4vw' : 'unsetc©'};

        .ServiceButton {
            flex: 1;
        }
    }

    .Youtube {
        padding-bottom: ${props => props.ismobile ? '4vw' : '3vw'};
    }
`

const StyledEventRowImgContainer = styled.div<{
    ismobile: boolean
}>`    
    img {
        border-radius: 22px;
        width: 100%;
    }
`;

const StyledBuyButton = styled.button<{
    ismobile: boolean
}>`
    text-transform: uppercase;
    color: ${colors.quattro};
    border: 1px solid ${colors.quattro};
    background-color: transparent;
    font-size: 4vw;
    border-radius: 34px;
    width: 100%;
    height: 15vw;
    cursor: pointer;
`;
const StyledSoldoutButton = styled.button<{
    ismobile: boolean
}>`
    text-transform: uppercase;
    color: ${colors.quattro};
    background-color: transparent;
    font-size: 4vw;
    border-radius: 34px;
    width: 100%;
    height: 15vw;
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
`;

const Events = ({isMobile, posts, menuRoutes} : IEvents) => {
    return(
        <StyledEvents className="Events" ismobile={isMobile}>
            {/* <h3>TOUR 2024</h3> */}
            <h1>TOUR 2024</h1>
            {
                posts.map(post => {

                    const categoryTitle = () => {
                        for (const menuRoute of menuRoutes) {
                            if(menuRoute.object_id === post.categories[0].toString()){
                                return menuRoute.title
                            }
                        }
                    }
                    const img = post.featured_image_large && !post.featured_image_large.includes('default') && post.featured_image_large
                    const linkRoute = `/${post.type}/${categoryTitle()}/${post.id}`
                    const dates = Object.values(post.acf.date_spettacoli)
                    const almostOneDate = dates.some((date: any) => date.length > 1)
                    const YoutubeID = post.acf.youtube_id
                    const title = cleanWPtextWithDangerousHTML(post.title.rendered)
                    return(
                        <ul key={uuidv4()}>
                            <Link to={linkRoute}>
                                <StyledEventRow key={uuidv4()} ismobile={isMobile}>
                                    <h3>{title}</h3>
                                    {
                                        YoutubeID.length > 1 ?
                                            <Youtube videoId={YoutubeID} />
                                        :
                                            <StyledEventRowImgContainer ismobile={isMobile}>
                                                <img key={uuidv4()} src={img} alt={"ALT"} />
                                            </StyledEventRowImgContainer>
                                            
                                    }
                                    <div className="dates">
                                        <div>
                                            {
                                                almostOneDate ?
                                                    dates.map(date => {
                                                        return(
                                                            date !== null ?
                                                            
                                                                <div key={uuidv4()}>{date}</div>
                                                            :
                                                                <></>
                                                        )
                                                    })
                                                :
                                                    <div key={uuidv4()}>Date non disponibili</div>                         
                                            }
                                        </div>
                                        <ServiceButton text="Descrizione" />
                                    </div>
                                </StyledEventRow>
                            </Link>
                        </ul>
                    )
                })
            }
        </StyledEvents>
    )
}

export default Events