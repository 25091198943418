import _ from 'lodash'

export const removeAllSpaces = (string) => string.replace(' ','')
export const cleanUrlEncoding = (string) => (string.replace('%20',' ')).replace('+',' ')
export const replaceUnicode = (string) => string.replace("&#8217;", "'")
export const replace = (string, replaceThis, withThis) => string.replaceAll(replaceThis, withThis)
export const removeFirstChar = (string) => string.slice(1)
export const upperCaseFirstChar = (string) => string.charAt(0).toUpperCase() + string.slice(1)
export const lowerCaseFirstChar = (string) => string.charAt(0).toLowerCase() + string.slice(1)
export const removeHTMLTagElement = (string) => string.slice(3).substring(0, string.slice(3).length - 5)
export const formatDate = (string) => `${string.substring(8, 6)}/${string.substring(6, 4)}/${string.substring(0, 4)}`
export const cutStringWithoutCutWords = (string, maxLength) => {
    var trimmedString = string.substr(0, maxLength);
    return trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")))
}
export const fromArrayToCoupleOfArray = (arr, stringForFilterTheList) => {
    return(
        _.chunk(
            Object.values(
                Object.keys(arr)
                    .filter((key) => key.includes(stringForFilterTheList))
                    .reduce((obj, key) => {
                        return Object.assign(obj, {
                            [key]: arr[key]
                        });
                    }, {})
            )
            .filter((val) => val.length > 0), 2
        )
    )
}
export const truncateText = (text, wordCount) => {
    const plainText = text.replace(/<[^>]*>/g, '')
    const words = plainText.split(' ');
    const truncatedText = words.slice(0, wordCount).join(' ');
    if (words.length > wordCount) {
      return truncatedText + '...';
    } else {
      return truncatedText;
    }
}

export const cleanWPtextWithDangerousHTML = (text, wordCount) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = text;
    return tempElement.textContent;
}