import styled from "styled-components";
import { colors } from "THE_BLOG/THE_BLOG_GlobalStyle";
import { useWidthWindowSize } from "core/features/device";

const StyledServiceButton = styled.button<{
    ismobile: boolean,
}>`
    background: ${colors.quattro};
    color: ${colors.uno};
    padding: ${props => props.ismobile ? '3vw' : '1vw'};
    /* margin-bottom: ${props => props.ismobile ? '4vw' : '2vw'}; */
    font-size: ${props => props.ismobile ? '3vw' : '2vw'};
    border-radius: 5px;
    cursor: pointer;
`

const ServiceButton = ({text} : {text: string}) => {
    const isMobile = useWidthWindowSize() >= 768 ? false : true
    return <StyledServiceButton className="ServiceButton" ismobile={isMobile}>{text}</StyledServiceButton>
}

  export default ServiceButton