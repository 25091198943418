import styled from "styled-components";
import { colors } from "THE_BLOG/THE_BLOG_GlobalStyle";
import { useWidthWindowSize } from "core/features/device";
import Hero from "./Hero";
import spiego from '../imgs/davide/spiego_cut.jpg'
import { Link } from "react-router-dom";
import ServiceButton from "./ServiceButton";

const StyledPages = styled.div<{
    ismobile: boolean,
}>`
    font-family: 'Lato', sans-serif;    
    color: ${colors.tre};
    background: ${colors.tre};
    
    div {
        margin: ${props => props.ismobile ? '4vw' : '2vw 16vw 2vw 16vw'};
    }

    h1,p,br {
        padding-bottom: ${props => props.ismobile ? '7vw' : '5vw'};
    }
    
    h1 {
        color: ${colors.uno};
        font-size: ${props => props.ismobile ? '4.8vw' : '3vw'};
        text-transform: uppercase;
        font-weight: 900;
        padding-bottom: ${props => props.ismobile ? '4vw' : '3vw'};
    }

    h3 {
        color: ${colors.quattro};
        font-size: ${props => props.ismobile ? '4.8vw' : '3vw'};
        padding-bottom: ${props => props.ismobile ? '4vw' : '3vw'};
    }
    
    p {
        color: ${colors.uno};
        font-size: ${props => props.ismobile ? '4.8vw' : '2vw'};
        font-weight: 300;
        line-height: 1.4em;
    }
    
    br {
        display: none;
    }
`

const Bio = () => {
    const isMobile = useWidthWindowSize() >= 768 ? false : true
    
    return (
        <StyledPages className="Bio" ismobile={isMobile}>
            <div>
                <h3>BIO</h3>
                <h1>COME SCOPRO IL TEATRO</h1>
                <p>Sono un attore, autore e intrattenitore. La mia passione per il teatro e la comicità è sbocciata all’età di 23 anni, durante l’esperienza come animatore in un villaggio turistico, dove sono stato rapito dal fascino del cabaret e degli spettacoli di prosa. Da quel momento ...</p>
                <Link to="/page/Bio/22">
                    <ServiceButton text="Leggi di più" />
                </Link>
            </div>
            <Hero isMobile={false} img={spiego} borders={false} />
        </StyledPages>
    );
  }

  export default Bio