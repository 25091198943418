import { colors } from "THE_BLOG/THE_BLOG_GlobalStyle";
import styled from "styled-components";
import Logo from "../Logo";

// https://loading.io/css/
export const StyledLoadingSpinner = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${colors.tre};
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .lds-ripple {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
    }
    .lds-ripple div {
        position: absolute;
        border: 4px solid ${colors.uno};
        opacity: 1;
        border-radius: 50%;
        animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }
    .lds-ripple div:nth-child(2) {
        animation-delay: -0.5s;
    }
    @keyframes lds-ripple {
        0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
        }
        100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
        }
    }
`

const LoadingSpinner = () => {
    return (
        <StyledLoadingSpinner className="Spinner">
            <Logo />
            <div className="lds-ripple"><div></div><div></div></div>
        </StyledLoadingSpinner>
    )
}

export default LoadingSpinner