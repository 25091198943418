import { colors } from 'THE_BLOG/THE_BLOG_GlobalStyle';
import { useWidthWindowSize } from 'core/features/device';
import styled from 'styled-components'
import SocialIcons from './SocialIcons';
import Header from './Header';

const StyledSectioFooter = styled.div<{
  ismobile: boolean
}>`
  display: flex;
  gap: ${props => props.ismobile ? '7vw' : '2vw'};
  flex-direction: ${props => props.ismobile ? 'column' : 'column'};
  align-items: ${props => props.ismobile ? 'unset' : 'center'};
  background: ${colors.tre};
  padding: ${props => props.ismobile ? '4vw' : '2vw'};
  padding-top: ${props => props.ismobile ? '16vw' : '6vw'};
  
  a,p {
    font-family: 'Lato', sans-serif;
    color: ${colors.uno};
    font-size: ${props => props.ismobile ? '4.8vw' : '2vw'};
    font-weight: 300;
    line-height: 1.4em;
  }
`;
const StyledFinal = styled.div<{
  ismobile: boolean
}>`
  display: flex;
  gap: ${props => props.ismobile ? '5vw' : '2vw'};
  flex-direction: ${props => props.ismobile ? 'column' : 'row'};
  background: ${colors.tre};
  padding: ${props => props.ismobile ? '4vw' : 'unset'};
  padding-top: ${props => props.ismobile ? '7vw' : 'unset'};
  align-items: center;
  
  p {
    font-family: 'Lato', sans-serif;
    color: ${colors.uno};
    font-size: ${props => props.ismobile ? '4vw' : '2vw'};
    font-weight: 300;
    line-height: 1.4em;
  }
`;

const Footer = () => {
  const isMobile = useWidthWindowSize() >= 768 ? false : true
  return (
    <StyledSectioFooter className="Footer" ismobile={isMobile}>
      <a style={{textDecoration: "underline"}} href="mailto:davidefabbrocino.official@gmail.com" target='blank'>Work with me</a>
      <p>P. IVA - 02700130061</p>
      <StyledFinal ismobile={isMobile}>
        <SocialIcons />
        <Header isHide={true} color={colors.tre} />
        <p>© 2024 Copyright DAVIDEFABBROCINO.IT</p>
      </StyledFinal>
    </StyledSectioFooter>
  );
};

export default Footer;
