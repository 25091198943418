import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { TOGGLE_MENU } from 'THE_BLOG/store/menu/actions'
import { isMenuOpenSelector } from 'THE_BLOG/store/menu/selectors'
import btn_close from '../imgs/btn_close.png'
import btn_open from '../imgs/btn_open.png'

import $ from 'jquery'

const StyledCloseButtonContainer = styled.div<{
    ismobile: boolean
}>`
    display: flex;
    justify-content: center;
    padding-top: ${props => props.ismobile ? 'unset' : 'unset'};
    /* height: ${props => props.ismobile ? '5vw' : '3vw'}; */
    filter: invert(1);
    cursor: pointer;

    img {
        height: ${props => props.ismobile ? '5vw' : '3vw'};
    }
`

const MenuButton = ({isMobile}: {isMobile: boolean}) => {

    const dispatch = useDispatch()
    const isMenuOpen = useSelector(isMenuOpenSelector)
    const toggle = () => {
        let scroll;
        isMenuOpen ? scroll = "unset" : scroll = "hidden"
        $(`body`).css("overflow", scroll)
        dispatch(TOGGLE_MENU())
    }
    const margin = isMobile ? 4 : 2

    return (
        <StyledCloseButtonContainer id='StyledCloseButtonContainer' ismobile={isMobile} onClick={() => toggle()}>
            <img src={isMenuOpen ? btn_close : btn_open} />
        </StyledCloseButtonContainer>
    )
}

export default MenuButton