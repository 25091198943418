import Logo from "../SimpleLogo/SimpleLogo"
import SimpleMenu from "../SimpleMenu/SimpleMenu"

const SimpleHeader = () => {
    return(
        <>
            <div className="_simpleHeader">
                <Logo />
                <SimpleMenu />
            </div>
        </>
    )
}

export default SimpleHeader