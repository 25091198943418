import styled, { createGlobalStyle, ThemeProvider} from "styled-components";
import { useWidthWindowSize } from "core/features/device";
/** insert your globa style inside GlobaStyle */
/** when the project is ended, try to remove styledcomponent to check if is real used */

export const colors = {
    uno: "#fff",
    due: "black",
    tre: "#260000",
    quattro: "#ed1b6f",
}

export const mobileCommonFontSize = 1.2
export const mediaQ = 844

export const Theme = ({ children }: { children: any }) => {
    const widthWindowSize = useWidthWindowSize()
    // const mediaQ = 1190
    // const mediaQ = 1024

    const s = 1
    const m = 2
    
    //font
    const StyledParagraphFontSize = 
        widthWindowSize >= mediaQ ? 
            m
        : 
            s
 
    //margin
    //size        
    //padding
    //other

    const theme = {
        size: {
            StyledParagraphFontSize: `${StyledParagraphFontSize}vw`,
        },
        fontSize: {},
        margin: {},
        padding: {},
        other: {},
    }

    return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

const GlobalStyle = createGlobalStyle`
    // FONTS

    @font-face {
        font-family: 'fontLatoRegular';
        font-style: normal;
        font-weight: 400;
        src: url();
    }

    //GENERAL
    html,body,button,ul,figure{
        /* background-color: black; */
        border: 0;
        margin: 0;
        padding: 0;
    }
    
    html, body {
        scroll-behavior: smooth;
    }

    main {
        background-color: #260000;
    }

    html{
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        -font-smoothing: antialiased;
        scroll-behavior: smooth;
    }

    section {
        margin-top: 1vw;
    }

    a{
        text-decoration: none;
        color: white;
    }

    h1,h2,h3,h4,h5{
        padding: 0;
        margin: 0;
        line-height: 100%;
    }

    h1,h2,h3,h4,h5,strong{
        font-weight: 400;
        color: white;
    }

    p{
        font-size: 1.6vw;
        margin: 0;
        color: white;
    }

    img, figure {
        user-select: none
    }

    .zoomControl {
        cursor: pointer;
        font-size: 3vw;
        margin-left: -100%;
    }
    
    
    // WORDPRESS CLASS STYLE
    .has-text-align-center {
        text-align: center;
    }
    figure {
        img {
            width: 100%;
            height: auto;
        }
    }
    // SIMPLE COMPONENTS STYLES
    /* ._simplePostPreview, ._simpleFooter {
        display: flex;
        justify-content: space-between;
        //border: 1px solid;
        padding: 2vw;
        margin: 1vw;
    } */
    ._simplePostPreview {
        display: flex;
        img {
            width: 100%;
            height: auto;
        }
        div:nth-child(1) {
            flex: 3;
        }
        div:nth-child(2) {
            flex: 7;
            text-align: end;
        }
    }
    ._simplePost {
        border: 1px solid;
        padding: 7vw;
        margin: 1vw;
        h1 {
            padding-bottom: 1vw;
        }
    }    
`
export const StyledExampleComponent = styled.nav`
    font-size: ${props => props.theme.margin.StyledParagraphFontSize};
`
export const StyledPageOrPost = styled.div<{
    ismobile: boolean,
}>`
    font-family: 'Lato', sans-serif;
    color: ${colors.uno};
    background: ${colors.tre};
    
    .pageOrPost_container {
        padding: ${props => props.ismobile ? '14vw 4vw 14vw 4vw' : '2vw 30vw 2vw 30vw'};
    }
    
    .pageOrPost_container__article {
        padding-top: ${props => props.ismobile ? '4vw' : '2vw'};
    }
    
    figure {
        margin-top: ${props => props.ismobile ? '6vw' : '2vw'};
        img {
            border: ${props => props.ismobile ? '2px solid' : '4px solid'};
            border-color: ${props => props.ismobile ? colors.quattro : colors.quattro};
        }
        iframe {
            width: 100%;

        }
    }
    
    h1 {
        text-transform: uppercase;
        font-weight: 900;
    }
    
    p {
        padding-top: ${props => props.ismobile ? '6vw' : '2vw'};
        font-size: ${props => props.ismobile ? '4.8vw' : '1.5vw'};
        font-weight: 300;
        line-height: 1.4em;
    }

    br {
        display: none;
    }

    .Logo {
        position: absolute;
        left: 4vw;
        transform: rotate(270deg);
        padding: 0vw 1vw 1vw 0vw;
        position: fixed;
        left: -11vw;
        top: 13vw;
        z-index: 1;
    }
`
export { GlobalStyle }