import styled from 'styled-components'
import { colors } from 'THE_BLOG/THE_BLOG_GlobalStyle';
import { the_blog_title } from 'THE_BLOG/THE_BLOG_Settings';

export type IHero = {
  isMobile: boolean,
  img: string
  paddingTop?: string
  borders: boolean
}

const StyledSectionHero = styled.img<{
    ismobile: boolean
    paddingtop?: string
    borders: boolean
}>`
  width: 100%; 
  padding-top: ${props => props.ismobile ? 
      props.paddingtop ? props.paddingtop : `unset`
    : 
      `unset`
  };
  filter: contrast(1.15);
  background-color: ${colors.due};
  border: ${props => props.borders ? props.ismobile ? '2px solid' : '4px solid' : 'unset'};
  border-color: ${colors.quattro};
`;

const Hero = ({isMobile, img, paddingTop, borders} : IHero) => {
  return (
    /* <StyledSectionHero bgImage={img} className="Hero" ismobile={isMobile}>
      {<FadeOverlay direction="to top" color={colors.tre} height="5" yCoord="16"/>
      <FadeOverlay direction="to bottom" color={colors.tre} height="5" yCoord="53" /> }
    </StyledSectionHero>*/
    <StyledSectionHero 
      className="Hero" 
      src={img} 
      alt={the_blog_title} 
      ismobile={isMobile} 
      paddingtop={paddingTop} 
      borders={borders}
    />
  );
};

export default Hero;
