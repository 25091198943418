import { domain, pathHome } from "../../settings/settings";
import magicWordGif from "../../imgs/magicWord.gif"
import { Link } from "react-router-dom";

const Logo = () => {
    return(
        <Link to={pathHome}>
            <img
                className="_simpleLogo"
                src={magicWordGif}
                alt={domain}
            />
        </Link>
    )
}

export default Logo