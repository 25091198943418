import { IPost } from '../../store/posts/slice';
import { Link } from 'react-router-dom';
import { replaceUnicode } from 'core/features/strings';
import { truncateText } from 'core/features/strings';

type ISimplePostPreview = {
    post: IPost,
    render: boolean,
    menuRoutes: any[],
}

const SimplePostPreview = ({post, render, menuRoutes} : ISimplePostPreview) => {
    const truncatedContent = truncateText(post.content.rendered, 10);
    const img = post.featured_image_large && !post.featured_image_large.includes('default') && post.featured_image_large

    const categoryTitle = () => {
        for (const menuRoute of menuRoutes) {
            if(menuRoute.object_id === post.categories[0].toString()){
                return menuRoute.title
            }
        }
    }

    return(
        render ?
            <>
                <Link className="_simplePostPreview" to={`/${post.type}/${categoryTitle()}/${post.id}`}>
                    <div>
                        <img 
                            src={img} 
                            alt={replaceUnicode(post.title.rendered)} 
                        />
                    </div>
                    <div>
                        <h2>{replaceUnicode(post.title.rendered)}</h2>
                        <div dangerouslySetInnerHTML={{ __html: truncatedContent }} />
                    </div>
                </Link>
            </>
        :
            <></>
    )
}

export default SimplePostPreview
