import { ICustomizeSite } from "core/model/model"
import Home from "./comps/Home"
import { Theme, colors } from "./THE_BLOG_GlobalStyle"
import Header from "./comps/Header"
import Footer from "./comps/Footer"
import Page from "./comps/Page"
import Post from "./comps/Post"

/**
 * SIMPLY EMPTY COMPONENT
 * You can pass:
 *  - false (it returns a default component)
 *  - SimpleEmptyComponent (it returns <></> and you have to import it)
 *  - your component
 */

/** 
 * MODAL LOADER
 * You should use ModalLoader inside any component that use state injected by API request.
 * e.g.: if you use something like 'const menuRoutes = useSelector(menuRoutesSelector)'.
*/

/** 
 * THEME
 * If u need a Component see THE_BLOG_GlobalStyle's vars do like down here.
 * e.g.: <Theme><Component /></Theme>
*/

export const CUSTOMIZED_SITE: ICustomizeSite = {

    error: false,
    loading: false,
    logo: false,
    header: <Header isHide={false} color={colors.due} />,
    menu: false,
    home: <Theme><Home /></Theme>,
    page: <Theme><Page /></Theme>,
    post: <Theme><Post /></Theme>,
    category: false,
    footer: <Footer />,
    custom: false,
    help: false,
}

export default CUSTOMIZED_SITE
