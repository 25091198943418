import styled from 'styled-components'
import { Link } from 'react-router-dom';
import { pathHome } from 'core/settings/settings';
import { useSelector } from 'react-redux';
import { isMenuOpenSelector } from 'THE_BLOG/store/menu/selectors';
import { scrollUp } from 'core/features/utils';
import { useWidthWindowSize } from 'core/features/device';

const StyledLogo = styled.h1<{
  ismobile: boolean
}>`
  font-family: "Birthstone", cursive;
  font-weight: 400;
  font-style: normal;
  color: white; 
  text-shadow:
  0 0 5px #fff, 
  0 0 10px #fff, 
  0 0 5px #fff, 
  0 0 0px #fff, 
  0 0 0px #fff, 
  0 0 0px #fff; 
  font-size: ${props => props.ismobile ? '9vw' : '4vw'};
  text-align: center;
  text-transform: capitalize;
  user-select: none;
`
const StyledLogoContainer = styled.h1<{
  ishide: boolean
}>`
  display: ${props => props.ishide ? 'none' : 'flex'};
`

const Logo = () => {
  const isMobile = useWidthWindowSize() >= 768 ? false : true
  const isMenuOpen = useSelector(isMenuOpenSelector)

  return (
    <StyledLogoContainer 
      className="Logo"
      onClick={() => scrollUp()} 
      ishide={isMenuOpen} 
    >
      <Link to={pathHome}>
        <StyledLogo ismobile={isMobile}>Davide Fabbrocino</StyledLogo>    
      </Link>
    </StyledLogoContainer>
  )
};

export default Logo;
