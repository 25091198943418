import styled from 'styled-components'
import { colors } from 'THE_BLOG/THE_BLOG_GlobalStyle';
import Menu from './Menu';
import { useWidthWindowSize } from 'core/features/device';
import MenuButton from './MenuButton';
import Logo from './Logo';
import { isMenuOpenSelector, isMenuRouteErrorSelector, isMenuRouteLoadingSelector, isMenuRouteSuccessSelector } from 'THE_BLOG/store/menu/selectors';
import { useSelector } from 'react-redux';
import ModalLoader from 'core/components/ModalLoader/ModalLoader';

export type IHeader = {
  isHide: boolean,
  color: string
}

const StyledHeader = styled.div<{
  ismobile: boolean,
  ismenuopen: boolean,
}>`
  height: ${props => props.ismenuopen ? '100vh' : 'unset'};
  background-color: ${props => props.ismenuopen && colors.due};
  z-index: 1;
  width: 100%;
  position: fixed;
`;
const StyledSectionHeader = styled.div<{
  ismobile: boolean,
  color: string
  ishide: boolean
}>`
  height: ${props => props.ismobile ? 'unset' : '7vw'};
  padding: ${props => props.ismobile ? '4vw' : '0vw 2vw 0vw 2vw'};
  padding-bottom: ${props => props.ismobile ? '5vw' : 'unset'};
  font-family: 'Lato', sans-serif;
  text-transform: uppercase;
  margin: 0 !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  .Logo {
    position: absolute;
    left: 4vw;
    z-index: 1;
  }
`;
const StyledMenubuttonContainer = styled.div<{
  ismobile: boolean
  ishide: boolean
}>`
  display: ${props => props.ishide ? 'none' : 'flex'};
  flex-direction: ${props => props.ismobile ? 'column' : 'row'};
`;

const Header = ({isHide, color} : IHeader) => {
  const isMobile = useWidthWindowSize() >= 768 ? false : true
  const isMenuOpen = useSelector(isMenuOpenSelector)
  const isMenuRouteLoading = useSelector(isMenuRouteLoadingSelector)
  const isMenuRouteError = useSelector(isMenuRouteErrorSelector)
  const isMenuRouteSuccess = useSelector(isMenuRouteSuccessSelector)
  
  return (
    <ModalLoader  
      loading={isMenuRouteLoading}
      error={isMenuRouteError}
      success={isMenuRouteSuccess}
      componentOnLoading={<></>}
      componentOnError={<></>}
      componentOnSuccess={
        <StyledHeader className="Header" ismobile={isMobile}  ismenuopen={isMenuOpen}>
          <StyledSectionHeader color={color} ismobile={isMobile} ishide={isHide}>
            {isMobile && <Logo /> }
            <StyledMenubuttonContainer ismobile={isMobile} ishide={isHide}>
              <MenuButton isMobile={isMobile} />
            </StyledMenubuttonContainer>
          </StyledSectionHeader>
          <Menu isMobile={isMobile} />
        </StyledHeader> 
      }
    /> 
  );
};

export default Header;
